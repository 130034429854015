import { createRouter, createWebHistory } from 'vue-router'
import { loadToken } from '@/utils/common'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from) => {
  const token = loadToken()
  // 未登录可以访问的路由列表
  const guestRoutes = [
    'Login',
    'Callback',
    'LoginGoogleAuth',
    'QrCodePaymentSetPassword',
    'QrCodePaymentResetPassword',
    'QrCodePaymentBind2FA',
    'QrForgetPassword',
  ]
  // 未登录有条件的路由列表
  const conditionalRoutes = ['ContractPaymentStep', 'QrCodePaymentStep']
  // 是否未登录可以访问
  const isGuestPage =
    guestRoutes.indexOf(to.name) !== -1 ||
    (conditionalRoutes.indexOf(to.name) !== -1 &&
      ['0', '1'].indexOf(to.params.step[0]) !== -1)
  // 回到登录页
  if (!isGuestPage && !token) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
      },
    }
  }
  // 返回 false 以取消导航
  return true
})
export default router
