import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef } from "vue";
var _hoisted_1 = {
  class: "min-h-full"
};
var _hoisted_2 = {
  key: 0,
  class: "relative p-4 sm:p-7 min-w-0"
};
import Header from '@/components/header/index.vue';
import LeftNavList from '@/components/header/leftNavList.vue';
import DialogSwitchChain from '@/views/components/common/dialog-switchChain.vue';
import Maintenance from '@/components/maintenance/maintenance.vue';
import eventEmitter from '@/utils/eventEmitter';
import ConnectPanel from '@/components/connectPanel.vue';
import { onMounted, getCurrentInstance, ref, computed, watchEffect, provide, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { checkConnectStatus, initWs } from '@/utils/web3Hooks';
import { useI18n } from 'vue-i18n';
import { getI18nLocal } from '@/i18n/i18n';
import useSetup from '@/hooks/useSetup';
import { loadToken, parseToken } from './utils/common';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var _useI18n = useI18n({
        useScope: 'global'
      }),
      locale = _useI18n.locale;
    var _useSetup = useSetup(),
      checkSetup = _useSetup.checkSetup;
    var routeReload = ref(0);
    var route = useRoute();
    var router = useRouter();
    var store = useStore();
    var isConnected = computed(function () {
      return store.state._info.connectInfo.connected;
    });
    var chainId = computed(function () {
      return store.state._info.connectInfo.chainId;
    });
    var merchantId = computed(function () {
      return store.state._info.merchantInfo.merchantId;
    });
    var routerIsReady = ref(false);
    router.isReady().then(function () {
      routerIsReady.value = true;
    });
    var switchChainVisible = computed(function () {
      return !store.state._info.connectInfo.supported;
    });
    var globalProperties = getCurrentInstance().appContext.config.globalProperties;
    var elLocale = computed(function () {
      return getI18nLocal(locale.value);
    });

    // 维护状态
    var maintenance = computed(function () {
      return store.state._config.maintenance;
    });

    // 左侧导航抽屉显示
    var navDrawerVisible = ref(false);

    // 初始化当前网络配置
    var initNetworkInfo = function initNetworkInfo() {
      // 兼容当前版本store
      var allNetwork = store.state._config.allNetwork;
      var contractConfig = allNetwork.find(function (item) {
        return item.chainId === chainId.value;
      });
      if (contractConfig) {
        store.commit('_config/Update_contractConfig', contractConfig);
      }
    };

    // 初始化成功
    var loginInit = function loginInit() {
      // 每次登录成功刷新路由
      routeReload.value += 1;
      // 获取币种列表
      store.dispatch('_config/querySymbol', {
        bizType: '',
        chainId: chainId.value
      });
    };

    // 登录成功
    var loginSuccess = function loginSuccess() {
      console.log('loginSuccess', routeReload.value);
      store.commit('_info/Update_loginInfo', parseToken());
      // 每次登录成功刷新路由
      routeReload.value += 1;
      initNetworkInfo();
      // 检查初始化进度
      checkSetup();
      // 获取币种列表
      store.dispatch('_config/querySymbol', {
        bizType: '',
        chainId: chainId.value
      });
      // 合约列表
      store.dispatch('_info/queryContractList');
      // 获取余额列表
      store.dispatch('_info/queryBalance');
      // 初始化ws
      initWs();
    };
    router.beforeEach(function (to, from) {
      var token = loadToken();
      var contractFlag = store.state._info.merchantInfo.contractFlag;

      // contractFlag没有权限访问合约模块的账户重定向到二维码模块
      if (to.path.startsWith('/contract-payment') && contractFlag === false && token) {
        return {
          name: 'QrCodePayment'
        };
      }
      // 返回 false 以取消导航
      return true;
    });
    watchEffect(function () {
      if (isConnected.value && chainId.value) {
        // 兼容当前版本store
        initNetworkInfo();
      }
    });
    onMounted(function () {
      // 调试用
      window['vm'] = globalProperties;
      window['store'] = store;
      var token = loadToken();

      // 合约地址配置
      var getSignatureStr = function getSignatureStr() {
        return store.dispatch('_config/signatureStr');
      };
      var getAllNetwork = function getAllNetwork() {
        return store.dispatch('_config/queryAllNetwork');
      };
      // 初始化自动化连接
      Promise.all([getSignatureStr(), getAllNetwork()]).then(function () {
        checkConnectStatus();
        token && loginSuccess();
      });
      eventEmitter.on('LOGIN_INIT', loginInit);
      eventEmitter.on('LOGIN_SUCCESS', loginSuccess);
    });
    onUnmounted(function () {
      eventEmitter.off('LOGIN_INIT', loginInit);
      eventEmitter.off('LOGIN_SUCCESS', loginSuccess);
    });
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      var _component_el_drawer = _resolveComponent("el-drawer");
      var _component_el_config_provider = _resolveComponent("el-config-provider");
      return _ctx.$route.path.includes('/callback/') ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : (_openBlock(), _createBlock(_component_el_config_provider, {
        key: 1,
        message: {
          max: 3
        },
        locale: _unref(elLocale)
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(Header, {
            onToggleMenu: _cache[0] || (_cache[0] = function ($event) {
              return navDrawerVisible.value = !navDrawerVisible.value;
            })
          }), routerIsReady.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_unref(maintenance) === 1 ? (_openBlock(), _createBlock(Maintenance, {
            key: 0
          })) : _createCommentVNode("", true), !_unref(isConnected) && (_ctx.$route.path.includes('/contract-payment/') || '/' === _ctx.$route.path) ? (_openBlock(), _createBlock(ConnectPanel, {
            key: 1
          })) : (_openBlock(), _createBlock(_component_router_view, {
            key: routeReload.value
          }, {
            default: _withCtx(function (_ref) {
              var Component = _ref.Component;
              return [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: 0
              })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: 0
              })) : _createCommentVNode("", true)];
            }),
            _: 1
          }))])) : _createCommentVNode("", true)]), _createVNode(_component_el_drawer, {
            modelValue: navDrawerVisible.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return navDrawerVisible.value = $event;
            }),
            class: "!w-72",
            direction: "ltr",
            "with-header": false
          }, {
            default: _withCtx(function () {
              return [_createVNode(LeftNavList, {
                onClick: _cache[1] || (_cache[1] = function ($event) {
                  return navDrawerVisible.value = false;
                })
              })];
            }),
            _: 1
          }, 8, ["modelValue"]), _createVNode(DialogSwitchChain, {
            modelValue: _unref(switchChainVisible),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return _isRef(switchChainVisible) ? switchChainVisible.value = $event : null;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["locale"]));
    };
  }
};