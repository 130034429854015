import {
  getAccountBalance,
  getCollectionBalance,
  getCollectionAssets,
} from '@/api/account'
import { getBrowserUrl } from '@/web3/tools'
import {
  getMerchantInfo,
  getWalletAddressList,
  getMerchantDetail,
  getMerchantList,
  getSymbolList,
  queryCashierSign,
} from '@/api/merchant'
import { getContractPage } from '@/api/contract'
import { getPayoutConfig } from '@/api/payout'
import { payoutPendingShow } from '@/api/merchant'
import { getTokenBalance } from '@/utils/web3Hooks'
import { parseJSON } from '@/utils/common'
import { groupBy } from 'lodash'

const getDefaultState = () => {
  return {
    connectInfo: {
      account: '', // 账号
      chainId: '', // 链id
      connected: false, // 是否已连接
      supported: true, // 是否支持
      loading: false, // 连接中
      network: '', // 网络
      wallet: '', // 钱包类型
    },
    merchantBalance: {},
    merchantInfo: {}, // 商户设定信息
    contractList: [], //
    payoutContractList: [], //
    managerList: [], // 管理员列表
    financialList: [], // 财务列表
    allWalletAddressList: [], // 钱包地址
    contractBalance: {}, // 合约资金
    contractBalanceTime: {},
    operatorList: [], // 运营列表
    symbolList: [], // 币种列表
    payoutInfo: {},
    loginInfo: { type: 1 }, // 登录方式 1 钱包  2 邮箱
    merchantSignInfo: {
      cashierSignEnabled: false, // 默认关闭收银台签名
      publicKey: '', // 公钥
    }, // 商户签名信息
    payoutPendingShow: {
      contractLimitOpened: true, // 是否可以关闭
      showIndashboard: false, // dashboard显示
    },
    setupInfo: {
      contractSetUp: false,
      contractStep: 0,
      qrCodeSetUp: false,
      qrCodeStep: 0,
    }, // 初始化信息
    allCollectionSymbols: [], // 收款币种列表
    contactInfo: {},
    collectionBalance: {}, // 收款账户余额
    collectionAssets: [], // 收款账户资产列表
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    Reset_State(state) {
      Object.assign(state, getDefaultState())
    },
    Reset_LoginInfo(state) {
      Object.assign(state, {
        ...getDefaultState(),
        connectInfo: state.connectInfo,
      })
    },
    Reset_ConnectInfo(state) {
      state.connectInfo = getDefaultState().connectInfo
    },
    Update_ConnectInfo(state, data) {
      state.connectInfo = {
        ...state.connectInfo,
        ...data,
      }
    },
    Update_ConnectedStatus(state, data) {
      state.connectInfo.connected = data
    },
    Update_loginInfo(state, data) {
      state.loginInfo = Object.assign(state.loginInfo, data)
    },
    Update_ConnectedAccount(state, data) {
      state.connectInfo.account = data
    },
    Update_ConnectedChainId(state, data) {
      state.connectInfo.chainId = data
    },
    Update_ConnectedSupported(state, data) {
      state.connectInfo.supported = data
    },
    Update_ConnectedLoading(state, data) {
      state.connectInfo.loading = data
    },
    Update_merchantBalance(state, data) {
      state.merchantBalance = data
        ? {
            ...state.merchantBalance,
            ...data,
          }
        : {}
    },
    Update_merchantInfo(state, data) {
      state.merchantInfo = data
    },
    Update_contractList(state, data) {
      state.contractList = data
    },
    Update_payoutContractList(state, data) {
      state.payoutContractList = data
    },
    // 管理员列表
    Update_managerList(state, data) {
      state.managerList = data || []
    },
    // 财务列表
    Update_financialList(state, data) {
      state.financialList = data || []
    },
    // 出金列表
    Update_allWalletAddressList(state, data) {
      data = data || []
      state.allWalletAddressList = data.map((item) => {
        return {
          ...item,
          name: item.alias || item.name, // deploy 必须传name
        }
      })
    },
    Update_contractBalanceTime(state, data) {
      // 余额请求时间
      state.contractBalanceTime[data.contractAddress] = {
        ...state.contractBalanceTime[data.contractAddress],
        ...data.time,
      }
    },
    Update_contractBalance(state, data) {
      // 余额
      state.contractBalance[data.contractAddress] = {
        ...state.contractBalance[data.contractAddress],
        ...data.balance,
      }
    },
    // 运营列表
    Update_operatorList(state, data) {
      state.operatorList = data
    },
    // 币种列表
    Update_symbolList(state, data) {
      state.symbolList = data
    },
    // 代付配置
    Update_payoutInfo(state, data) {
      state.payoutInfo = data
    },
    Update_merchantSignInfo(state, data) {
      state.merchantSignInfo = data
    },
    Update_payoutPendingShow(state, data) {
      state.payoutPendingShow = data
    },
    Update_allCollectionSymbols(state, data) {
      state.allCollectionSymbols = data || []
    },
    // 更新初始化进度
    Update_setupInfo(state, data) {
      state.setupInfo = data
    },
    Update_contactInfo(state, data) {
      state.contactInfo = data
    },
    Update_collectionBalance(state, data) {
      state.collectionBalance = data || {}
    },
    Update_collectionAssets(state, data) {
      state.collectionAssets = data || []
    },
  },
  actions: {
    // 获取业务余额
    queryBalance({ commit }) {
      return getAccountBalance().then((res) => {
        if (res.check()) {
          commit('Update_merchantBalance', res.data)
        }
        return res
      })
    },
    // 获取收款账户余额
    queryCollectionBalance({ commit }) {
      return getCollectionBalance().then((res) => {
        if (res.check()) {
          commit('Update_collectionBalance', res.data)
        }
        return res
      })
    },
    // 获取商户信息
    queryMerchantInfo({ commit }) {
      return getMerchantInfo().then((res) => {
        if (res.check()) {
          commit('Update_merchantInfo', res.data)
        }
        return res
      })
    },
    queryMerchantDetail({ commit }) {
      // 商户明细
      return getMerchantDetail().then((res) => {
        if (res.check()) {
          const {
            financialList = [],
            withdrawAddressList = [],
            managerList = [],
            tokens = [],
            contactInfos = {},
            ...rest
          } = res.data
          commit('Update_managerList', managerList)
          commit('Update_financialList', financialList)
          commit('Update_allWalletAddressList', withdrawAddressList)
          commit('Update_allCollectionSymbols', tokens)
          commit('Update_contactInfo', contactInfos)
          commit('Update_merchantInfo', rest)
          commit('Update_merchantInfo', rest)
        }
        return res
      })
    },
    // 查询管理员列表
    queryAdminstratorList({ commit }) {
      return getMerchantList({ roleType: 2 }).then((res) => {
        if (res.check()) {
          commit('Update_managerList', res.data)
        }
        return res
      })
    },
    // 查询财务列表
    queryFinancialList({ commit }) {
      return getMerchantList({ roleType: 3 }).then((res) => {
        if (res.check()) {
          commit('Update_financialList', res.data)
        }
        return res
      })
    },
    // 查询合约列表
    queryContractList({ commit }) {
      return getContractPage().then((res) => {
        if (res.check()) {
          const contractList = res.data.list.map((item) => {
            return {
              ...item,
              // 格式化财务/出金地址
              ownerAddress: parseJSON(item.ownerAddress, []),
              withdrawalAddress: parseJSON(item.withdrawalAddress, []),
            }
          })
          commit('Update_contractList', contractList)
        }
        return res
      })
    },
    // 查询代付合约列表
    queryPayoutContractList({ commit }) {
      return getContractPage({ entityParam: { contractType: 2 } }).then(
        (res) => {
          if (res.check()) {
            const payoutcontractList = res.data.list.map((item) => {
              return {
                ...item,
                // 格式化财务地址
                ownerAddress: parseJSON(item.ownerAddress, []),
              }
            })
            commit('Update_payoutContractList', payoutcontractList)
          }
          return res
        }
      )
    },
    // 获取钱包地址
    queryWalletAddressList({ commit }) {
      return getWalletAddressList({}).then((res) => {
        if (res.check()) {
          commit('Update_allWalletAddressList', res.data)
        }
        return res
      })
    },
    queryTokenBalance({ commit, state }, params) {
      const lastQueryTime =
        state.contractBalanceTime[params.contractAddress]?.[params.symbol] || 0
      if (
        +new Date() - lastQueryTime < 1000 * 60 &&
        params.forceUpdate !== true
      ) {
        // 忽略1分钟内的请求
        return null
      }
      // 更新请求token时间
      commit('Update_contractBalanceTime', {
        contractAddress: params.contractAddress,
        time: {
          [params.symbol]: +new Date(),
        },
      })
      return getTokenBalance(params.tokenAddress, params.contractAddress).then(
        (res) => {
          if (res) {
            commit('Update_contractBalance', {
              contractAddress: params.contractAddress,
              balance: {
                [params.symbol]: res,
              },
            })
          } else {
            // 如果调取失败就重置
            commit('Update_contractBalanceTime', {
              contractAddress: params.contractAddress,
              time: {
                [params.symbol]: 0,
              },
            })
          }
          return res
        }
      )
    },
    // 获取运营列表
    queryOperatorList({ commit }) {
      return getMerchantList({ roleType: 1 }).then((res) => {
        if (res.check()) {
          commit('Update_operatorList', res.data)
        }
        return res
      })
    },
    // 获取币种列表
    querySymbolList({ commit }) {
      return getSymbolList({}).then((res) => {
        if (res.check()) {
          commit('Update_symbolList', res.data)
        }
        return res
      })
    },
    // 查询代付信息
    queryPayoutInfo({ commit }) {
      return getPayoutConfig().then((res) => {
        if (res.check()) {
          commit('Update_payoutInfo', res.data)
        }
        return res
      })
    },
    // 查看签名信息
    queryMerchantSignInfo({ commit }) {
      return queryCashierSign().then((res) => {
        if (res.check()) {
          commit('Update_merchantSignInfo', res.data)
        }
        return res
      })
    },
    // 是否显示在dashboard
    payoutPendingShow({ commit }) {
      return payoutPendingShow().then((res) => {
        if (res.check()) {
          commit('Update_payoutPendingShow', res.data)
        }
        return res
      })
    },
    // 获取收款账户资产
    queryCollectionAssets({ commit }) {
      return getCollectionAssets({}).then((res) => {
        if (res.check()) {
          const data = groupBy(res.data, 'chainId')
          commit('Update_collectionAssets', data)
        }
        return res
      })
    },
  },
  getters: {
    // 获取当前钱包地址列表
    getCurrentWalletList(state, getters, rootState) {
      const { addressType } = rootState._config.contractConfig
      const list = state.allWalletAddressList.filter(
        (item) => item.addressType === addressType
      )
      return list || []
    },
    // 获取所有收款币种列表
    getAllCollectionSymbols(state) {
      let obj = {}
      state.allCollectionSymbols.filter((item) => {
        if (!obj[item.symbol]) {
          obj[item.symbol] = item.symbol
        }
      })
      return obj
    },
    // 浏览器地址
    getBrowserUrls(state) {
      const chainId = state.connectInfo.chainId
      return getBrowserUrl(chainId)
    },
  },
}
