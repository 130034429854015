import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-79cb0ffe"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "account-info"
};
const _hoisted_2 = {
  key: 0,
  src: "/images/common/email.svg",
  width: "15",
  class: "opacity-40"
};
const _hoisted_3 = {
  key: 2,
  class: "flex overflow-hidden items-center pl-2 w-full text-xs flex-1"
};
const _hoisted_4 = {
  class: "truncate"
};
import NetworkIcon from '@/components/icons/networkIcon.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { getEllipsisData } from '@/utils/common';
import Clipboard from 'clipboardy';
import Toast from '@/utils/toast';
export default {
  __name: 'accountInfo',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const {
      t
    } = useI18n({
      useScope: 'global'
    });
    const store = useStore();
    const address = computed(() => {
      if (props.type === 'email') {
        return store.state._info.loginInfo.address;
      } else {
        return store.state._info.connectInfo.account;
      }
    });

    // 复制
    const handleCopy = () => {
      Clipboard.write(address.value).then(() => {
        Toast.success(t('common.copySuccess'));
      });
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.type === 'email' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createBlock(NetworkIcon, {
        key: 1,
        size: "middle"
      })), _unref(address) ? (_openBlock(), _createElementBlock("p", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(_unref(getEllipsisData)(_unref(address), 10)), 1)])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "right", {}, () => [_createElementVNode("i", {
        class: "bm bm-copy hover:text-main cursor-pointer",
        onClick: handleCopy
      })])]);
    };
  }
};