import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "h-20 pl-4 pr-7 sm:pl-7 flex justify-between border-b border-bd max-xl:bg-skyBlue"
};
var _hoisted_2 = {
  class: "flex items-center max-xl:w-full max-xl:justify-between"
};
var _hoisted_3 = {
  class: "flex items-center"
};
import Logo from './logo.vue';
import NavList from './navList.vue';
import AccountBox from './accountBox.vue';
import Language from './language.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import useMediaQuery from '@/hooks/useMediaQuery';
export default {
  __name: 'index',
  emits: ['toggle-menu'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var isLgScreen = useMediaQuery('lg');
    var isXsScreen = useMediaQuery('xs');
    var loginInfo = computed(function () {
      return store.state._info.loginInfo;
    });
    var handleClickMenu = function handleClickMenu() {
      emit('toggle-menu');
    };
    return function (_ctx, _cache) {
      var _unref2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Logo, {
        class: "mr-6"
      }), !_unref(isLgScreen) ? (_openBlock(), _createBlock(NavList, {
        key: 0,
        class: "flex items-center h-full"
      })) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [!_unref(isXsScreen) ? (_openBlock(), _createBlock(AccountBox, {
        key: 0,
        type: (_unref2 = _unref(loginInfo)) === null || _unref2 === void 0 ? void 0 : _unref2.addressType
      }, null, 8, ["type"])) : _createCommentVNode("", true), _unref(isLgScreen) ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "ml-4 w-6 h-4 cursor-pointer",
        src: "/images/common/menu.svg",
        onClick: handleClickMenu
      })) : _createCommentVNode("", true), _createVNode(Language, {
        class: "ml-4"
      })])]);
    };
  }
};